/**
 * Check nuxt.config.ts for where pinia is initialized
 * https://pinia.vuejs.org/
 *
 */
import { defineStore } from 'pinia';
import { fetchSolarCost } from '~/api/bulk-local/';
import { SolarCostAPIData } from '~/types/localData';
import { PageStoryInterface } from '~/types/content';

export const apiKey = (stateParam: string, county?: string, city?: string) =>
    `${stateParam}${county ? `-${county}` : ''}${city ? `-${city}` : ''}`;

export const useSolarCostDataStore = defineStore('SolarCostData', {
    state: () => ({
        apiData: {} as Record<string, SolarCostAPIData>,
        pageData: {} as PageStoryInterface,
    }),
    getters: {
        getApiData: (state) => (stateParam: string, county?: string, city?: string) =>
            state.apiData[apiKey(stateParam, county, city)],
        getPageData: (state) => state.pageData,
    },
    actions: {
        setPageData(pageData: PageStoryInterface) {
            this.pageData = pageData;
        },
        async setApiData(payload: SolarCostAPIData, stateParam: string, county?: string, city?: string) {
            const key = apiKey(stateParam, county, city);
            if (this.apiData[key]) {
                return;
            }

            // Payload can be an empty object if generate doesnt provide data
            if (payload && Object.keys(payload).length) {
                this.apiData[key] = payload;
                return;
            }

            try {
                const apiData: SolarCostAPIData = await fetchSolarCost(stateParam, county, city);
                this.apiData[key] = apiData;
            } catch (e) {}
        },
    },
});
