import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { datadogRum } from '@datadog/browser-rum';

// https://app.datadoghq.com/rum/application/a3b23589-e9d3-4321-9a52-8c7a30f3fb22
export default defineNuxtPlugin(({ isDev, $config }) => {
    if (isDev || !process.client) return;

    datadogRum.init({
        applicationId: '5a3eb96e-35f8-4d75-a302-c5322c8746d0',
        clientToken: 'pubec49a4704d755a69331e72e28d251af7',
        site: 'datadoghq.com',
        service: 'es-cms-local-data',
        env: $config.DEPLOY_ENV,
        version: $config.VERSION,
        sampleRate: 100,
        premiumSampleRate: 100,
        trackInteractions: true,
        trackFrustrations: true,
        defaultPrivacyLevel: 'allow',
    });

    datadogRum.startSessionReplayRecording();
});
