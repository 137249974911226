import { StoryblokBlokInterface, StoryblokRichTextInterface } from '~/types/storyblok';

export default class StoryblokBlokClass implements StoryblokBlokInterface {
    _uid: number;

    _editable: string;

    component: string;

    HeaderText?: string;

    body: StoryblokBlokInterface[];

    richtext?: StoryblokRichTextInterface;

    // eslint-disable-next-line no-undef
    [x: string | number | symbol]: unknown;

    constructor() {
        /* eslint-disable no-underscore-dangle */
        this._uid = 0;
        this._editable = '';
        /* eslint-enable no-underscore-dangle */
        this.component = '';
        this.HeaderText = '';
        this.body = [];
    }
}
