/* eslint-disable no-console */
import axios from 'axios';
import rateLimit from 'axios-rate-limit';

// Limit to X requests per second
const api = rateLimit(axios.create(), { maxRPS: 50 });

const log = (msg: string, ...args: any) => {
    if (!process.client && process.env.CI !== '1') {
        console.log(`[BULK-LOCAL-API]${msg}`, ...args);
    }
};
const error = (msg: string, ...args: any) => {
    if (!process.client) {
        console.error(`[BULK-LOCAL-API]${msg}`, ...args);
    }
};

// During a PR we dont generate all possible routes just some
const deployEnv = process.env.DEPLOY_ENV || 'dev';
const previewBuild = !(deployEnv === 'prod' || deployEnv === 'int');

// See local-data/solar-companies.vue
// TODO: Can this be an env variable some way to also be shared across ci files?
const solarCompaniesPreviewRoutes = [
    '/local-data/solar-companies/ma/',
    '/local-data/solar-companies/ma/suffolk-county/',
    '/local-data/solar-companies/ma/suffolk-county/boston/',
];

/**
 * RUNTIME EXPORTS
 * These are designed to be used on client side but could be used on build time.
 */

export const fetchLocalData = async (slug: string, state?: string, county?: string, city?: string) => {
    try {
        const resp = await api.get(`${process.env.BULK_LOCAL_SERVICE_DOMAIN}/bulk-local/page/${slug}`, {
            params: { state, county, city },
        });
        const { data } = resp;
        log('[SUCCESS]', resp.request?.res?.responseUrl, resp.status, resp.statusText);
        return data;
    } catch (e: any) {
        error('[FAILURE]', e);
        if (!process.client) {
            // Fail on generate error
            process.exit(1);
        }
        return null;
    }
};

/**
 * BUILD TIME EXPORTS
 * These could be used on client side but are designed for build time only.
 */
export const extractLastSegmentFromUrl = (url: string) => url.split('/').filter(Boolean).pop();

export const seedSolarCompanies = async () =>
    api.get(`${process.env.BULK_LOCAL_SERVICE_DOMAIN}/bulk-local/states/SolarCompany`);

export const fetchSolarCompanies = async (state?: string, county?: string, city?: string) =>
    fetchLocalData('SolarCompany', state, county, city);

export const fetchSolarCost = async (state?: string, county?: string, city?: string) =>
    fetchLocalData('SolarCost', state, county, city);

export const bulkLocalRoutes = async (seedCall: Function, apiCall: Function, slug: string) => {
    try {
        const routes: any[] = [];
        const res = await seedCall();

        const states = Object.keys(res.data);
        const statePayloads = await Promise.all(states.map((state) => apiCall(state)));

        await Promise.all(
            statePayloads.map(async (statePayload, stateIndex) => {
                if (!statePayload) return;

                const state = states[stateIndex];
                const stateRoute = {
                    route: `/local-data/${slug}/${state}/`,
                    payload: statePayload,
                };

                log('[FOUND]', stateRoute.route);
                routes.push(stateRoute);

                const counties = statePayload.childUrlsList;
                const countyPayloads = await Promise.all(
                    counties.map((county: any[]) => apiCall(state, extractLastSegmentFromUrl(county[1]))),
                );

                await Promise.all(
                    countyPayloads.map(async (countyPayload, countyIndex) => {
                        if (!countyPayload) return;

                        const county = extractLastSegmentFromUrl(counties[countyIndex][1]);
                        const countyRoute = {
                            route: `/local-data/${slug}/${state}/${county}/`,
                            payload: countyPayload,
                        };

                        routes.push(countyRoute);
                        log('[FOUND]', countyRoute.route);

                        const cities = countyPayload.childUrlsList;
                        const cityPayloads = await Promise.all(
                            cities.map((city: any[]) => apiCall(state, county, extractLastSegmentFromUrl(city[1]))),
                        );

                        cityPayloads.forEach((cityPayload, cityIndex) => {
                            if (!cityPayload) return;

                            const city = extractLastSegmentFromUrl(cities[cityIndex][1]);
                            const cityRoute = {
                                route: `/local-data/${slug}/${state}/${county}/${city}/`,
                                payload: cityPayload,
                            };

                            routes.push(cityRoute);
                            log('[FOUND]', cityRoute.route);
                        });
                    }),
                );
            }),
        );

        log(`[DONE] Found ${routes.length} routes`);
        return routes;
    } catch (e) {
        error('[ERROR]', e);
        return [];
    }
};

// Cache so that route generation and sitemap generation don't require 2 separate calls
let solarCompaniesRoutesCached: string[] | any[] = [];

export const solarCompaniesRoutes = async () => {
    if (solarCompaniesRoutesCached.length) {
        return solarCompaniesRoutesCached;
    }
    try {
        const results = previewBuild
            ? solarCompaniesPreviewRoutes
            : await bulkLocalRoutes(seedSolarCompanies, fetchSolarCompanies, 'solar-companies');
        solarCompaniesRoutesCached = results;
        return results;
    } catch (e) {
        error('[ERROR]', e);
        return [];
    }
};
